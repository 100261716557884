import React from 'react';

const AwardsItem = () => {
    return (
        <svg preserveAspectRatio="none" className="svg-max" viewBox="-25 58.1 92 29.9">
            <g transform="matrix(0.995, 0.105, -0.105, 0.995, 13.145, -5.531)">
                <path d="M60.1,59.4l0,16.3l-2,0l0-13.4c-2,3-8.4,11.5-20.1,18.1c-6,3.3-13.8,6.3-23.7,7.4c-11.8,1.2-26.4-0.3-44-7.5l0.7-1.8
			        c17.5,7.1,43.1,12.9,66,0.2c11-6.1,17.2-14.2,19.3-17.2l-12.7,0l0-2l14.5,0"/>
            </g>
        </svg>

    )
}
const AwardsItem1 = () => {
    return (
        <svg className="svg-max" preserveAspectRatio="none" viewBox="0 0 798.266 560.37">
            <path d="M746.186,472.305,569.506,11.085a6.122,6.122,0,0,0-5.488-4.075L167.846,0a5.632,5.632,0,0,0-5.365,3.883L0,
            459.1S747.793,476.5,746.186,472.305Z" transform="translate(740.627 560.37) rotate(-173)" />
        </svg>
    )
}
const AwardsItem2 = () => {
    return (
        <svg className="svg-max" preserveAspectRatio="none" viewBox="0 0 905 905">
            <circle cx="452.5" cy="452.5" r="452.5" />
        </svg>
    )
}
const AwardsItem3 = () => {
    return (
        <svg className="svg-max" preserveAspectRatio="none" viewBox="0 0 678.73 575.862">
            <path id="Path_10507" data-name="Path 10507" d="M0,0H478.085C478.085,334.067,264.031,604.9,0,604.9H0Z" transform="matrix(0.174, 0.985, -0.985, 0.174, 595.711, -9.61)" />
        </svg>

    )
}
const AwardsItem4 = () => {

    return (
        <svg className="svg-max" preserveAspectRatio="none" viewBox="0 0 921.553 728.663">
            <path d="M342.5,172.591,423.523,0l78.089,181.671L630.273,52.984,658.159,209.4l154.58-62.74-38.3,155.253,112.573,54.363L770.29,427.517l19.257,143.6-148.66-67.893L623.142,660.451,499.679,552.887,410.7,683.177,322.568,540.97,170.323,636.348V462.525L0,452.236l141.67-102.85L45.757,179.893l155.395,54.877V68.414Z"></path>
        </svg>

    )
}


export { AwardsItem1, AwardsItem2, AwardsItem3, AwardsItem4, AwardsItem };
