import React, { memo as Memo, useMemo, useEffect, useState, useRef } from 'react'
import { gsap, CSSPlugin } from 'gsap'
import _ from 'lodash'

//* HOCs
import withUIContext from '@context/consumerHOC/UIConsumer';
import withDataContext from '@context/consumerHOC/DataConsumer';
import { withRouter } from 'next/router';

//*Components
import AwardsBlack from './AwardsBlack'
import AwardsWhite from './AwardsWhite'

//*style 
import style from './style.module.scss'

gsap.registerPlugin(CSSPlugin)

const Awards = Memo(({ global, anime, active, ...props }) => {
    //! States
    const [data, setData] = useState()

    //! Ref's
    const topRef = useRef()

    useEffect(() => setData(global?.[props.router.locale]), [global, props.router.locale])

    const count = useMemo(() => !props.count ? data?.awards.length : 4, [props.count, data])

    const itemsArr = useMemo(() => data && _.orderBy(data.awards, ['year'], ['desc']), [data])

    const items = useMemo(() => {
        return itemsArr && itemsArr.map((item, ind) => {
            return ind < count && (
                props.type !== 1
                    ?
                    <AwardsBlack key={ind} ind={ind} anime={anime} {...item} />
                    :
                    <AwardsWhite key={ind} {...item} />
            )
        })
    }, [itemsArr, anime, count])

    //! Animation
    const tl = useMemo(() => new gsap.timeline({ delay: 0.2 }), [])

    useEffect(() => {
        if (active) {
            tl.staggerTo(
                [topRef.current.childNodes], 0, {
                css: {
                    opacity: 1
                }
            }, 0.2)
        }
        else {
            tl.staggerTo(
                [topRef.current.childNodes], 0, {
                css: {
                    opacity: 0
                },
                delay: 0
            }, 0)
        }

        return () => {
            tl.clear()
        }
    }, [active])

    return (
        <div className={`${style.awardsItemWrap} ${props.className || ''} ${props.type !== 1 ? style.awardsItemBlack : ""}`} ref={topRef}>
            {items}
        </div>
    )
})

export default withRouter(withUIContext(withDataContext(Awards, ['global'])))